import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
//import { log } from 'console';
//import { fbcReviews } from '../interfaces/fbc-reviews';
import { map } from 'rxjs/operators';

const URL = 'https://api.voordeelvloeren.nl/feedbackcompany.php';

interface fbcReviews {
    score: string;
    total: string;
    count: string;
}

@Injectable({
    providedIn: 'root'
})
export class FBCReviewsService {
    public responseCache = new Map();

    response: fbcReviews;
    constructor(private http: HttpClient) {}

    public getFBCReviews(): Observable<fbcReviews> {
        //public getFBCReviews() {
        // const beersFromCache = this.responseCache.get(URL);
        // if (beersFromCache) {
        //     return of(beersFromCache);
        // }
        // //const beersFromCache = this.responseCache.get(URL);
        // // if (beersFromCache) {
        // //     console.log('345');
        // //     return beersFromCache;
        // // }
        // // this.httpClient
        // //     .get('https://api.voordeelvloeren.nl/feedbackcompany.php')
        // //     .subscribe((res) => {
        // //         localStorage.setItem('fbc-score', JSON.stringify(res['score']));
        // //         localStorage.setItem('fbc-total', JSON.stringify(res['total']));
        // //         localStorage.setItem('fbc-count', JSON.stringify(res['count']));
        // //     });
        // // this.http
        // //     .get('https://api.voordeelvloeren.nl/feedbackcompany.php')
        // //     .subscribe((res) => {
        // //         this.response = res;
        // //     });
        // // .subscribe(res) => {
        // //         localStorage.setItem('fbc-score', JSON.stringify(res['score']));
        // //         localStorage.setItem('fbc-total', JSON.stringify(res['total']));
        // //         localStorage.setItem('fbc-count', JSON.stringify(res['count']));
        // //     });
        // //const response = this.http.get<fbcReviews>(URL);
        // // this.http
        // //     .get<fbcReviews>(
        // //         'https://api.voordeelvloeren.nl/feedbackcompany.php'
        // //     )
        // //     .pipe(map((users: User[]) => users.map((user) => new User(user))));
        // // .subscribe((res) => {
        // //     console.log('1x1');
        // //     //  this.response = res;
        // //     //  console.log(this.response);
        // //     console.log(res['score']);
        // // });
        // // this.http
        // //     .get('https://api.npms.io/v2/search?q=scope:angular')
        // //     .subscribe(
        // //         (result) => {
        // //             // Handle result
        // //             //console.log(result);
        // //             console.log('JAAAAAAAAAAAA');
        // //         },
        // //         (error) => {
        // //             console.log('EEEEEEERRRRRORRRRRR');
        // //         },
        // //         () => {
        // //             console.log('HMMMMMM');
        // //         }
        // //     );
        // // .subscribe((data) => {
        // //     this.response = data.total;
        // // });
        // // response.subscribe((beers) => this.responseCache.set(URL, beers));
        // const response = this.http.get<fbcReviews>(URL);
        // response.subscribe((beers) => this.responseCache.set(URL, beers));
        // //console.log('!!123!!');
        // return response;
        return this.http.get<fbcReviews>(
            'https://api.voordeelvloeren.nl/feedbackcompany.php'
        );
        //console.log(this.response);
        //return this.response;
    }
}
